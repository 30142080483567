.loading_page .c-modal__overlay {
  background: #fff;
}

.loading_page.change_css .c-modal__wrapper {
  display: none;
}

#shoppingCart .c-product-info-summary__info, .c-fold-out__header::after {
  align-self: flex-start;
}
