.c-logo[data-testid='appHeaderLogo-audi'] {
  width: 300px;
  height: 65px;
}

@media (max-width: 720px) {
  img.rentACar-logo {
    display: none;
  }
  .c-context-bar__nav-label {
    display: none;
  }
}
